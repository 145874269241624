@media screen and (width >=960px) {
  .page-property-report__left-column {
    min-width: 23.375rem;
  }
}

.sc-ai__content-container {
  max-height: 250px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  mask: linear-gradient(180deg, #000 0%, #000 60%, transparent 100%);
}

.sc-ai__content-container--expanded {
  max-height: 5000px;
  height: 100%;
  mask: none;
}

.ai__container .sc-container__body {
  border-radius: 0.8rem;
}
